export default {
  "page-results": {
    "acceptable-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptable vision"])},
    "snellen-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snellen value"])},
    "poor-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impaired vision"])},
    "normal-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal vision"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have impaired vision and will benefit from taking a vision test at an optometrist"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>You would probably benefit by contacting an optician. If an optician is not able to help you to see clearly, it is important that you find out why. An eye care specialist (ophthalmologist) can help you with this.<br><br>Always consult an optician or an eye care specialist for any medical questions or concerns about your vision."])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a new test"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have normal vision"])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have now completed your reading vision test\n"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Thanks for taking a DIY Digital Visual Acuity Test on eye-sight.org.<br>Always consult an optician or an eye care specialist for any medical questions or concerns about your vision.<br>"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your vision is below"])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Results at 40 cm:</b> left eye ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have acceptable vision"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have now completed your distance vision test\n"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Results at 2m:</b> left eye ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right eye"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left eye"])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a new test"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have correctly identified ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), " symbols."])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an optometrist cannot help you see clearly, it is important that you find you why.<br />Always contact an ophthalmologist to find out."])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Thanks for taking a DIY Digital Visual Acuity Test on eye-sight.org. If you are unsure whether you have good eyesight, start by contacting an optician. If an optician is not able to help you to see clearly, it is important that you find out why. An eye care specialist (ophthalmologist) can help you with this.<br><p>This eyesight test:</p><ul><li>Is not a medical device.</li><li>Is not suitable for any medical purposes.</li><li>Is intended for healthy individuals.</li><li>Is not meant to replace a professional eyesight test.</li><li>Does not collect any personal identifiable data about you, or anyone else. </li></ol><p>Always consult an optician or an eye care specialist for any medical questions or concerns about your vision.</p>"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back home"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>You have now completed your reading vision test.</h3><p>You have replied that you saw ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols clearly at a 40-cm distance.<br>Others can see 36 out of the 40 symbols clearly at the same distance.<sup>*</sup><br><em class='small'>* Source; Lisbeth Jelveh Sandfeld, PhD, senior consultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>You have now completed your distance vision test.</h3><p>You have replied that you saw ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols clearly at a 2-metre distance.<br>Others can see 40 out of the 40 symbols clearly at the same distance.<sup>*</sup><br><em class='small'>* Source; Lisbeth Jelveh Sandfeld, PhD, senior consultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "poor-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test indicates that you have <span class=\"bold\">impaired vision</span> and will benefit from taking an eyesight test at an optometrist."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the result of the eyesight test that you have just completed"])},
    "acceptable-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The result of you test indicates that your visual acuity is <span class=\"bold\">", _interpolate(_named("snellenResult")), "</span>. Your vision is therefore considered <span class=\"bold\">acceptable</span>, but you will benefit from taking an eyesight test at an optometrist."])},
    "normal-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The result of you test indicates that your visual acuity is <span class=\"bold\">", _interpolate(_named("snellenResult")), "</span>. Your vision is\ntherefore considered <span class=\"bold\">normal</span>."])},
    "acceptable-or-impaired-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact your eye doctor to discuss your results. If an optometrist is not able to help you to see clearly, it is important that you find out why. Always contact an eye care specialist (ophthalmologist) to find out what is causing your inability to see clearly."])},
    "normal-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test indicates that you have <span class=\"bold\">normal vision</span>."])},
    "for-everyone-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please document the results as instructed for the clinical investigation. Thank you for participating to the study.<br /><br />Remember to test your vison regularly."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your result"])},
    "thank-you-taking-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for taking a free online do-it-yourself eyesight test.<br />Remember to test your vison regularly and help others take the test."])},
    "results-based-input-distance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The result is based on your input in the test and the assumption that you have had ", _interpolate(_named("distance")), " between the screen on which you have taken the test and your eyes."])},
    "poor-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The result of you test indicates that your visual acuity is <span class=\"bold\">", _interpolate(_named("snellenResult")), "</span>. Your vision is therefore considered <span class=\"bold\">impaired</span>, and you will benefit from taking an eyesight test at an optometrist."])},
    "acceptable-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test indicates that you have <span class=\"bold\">acceptable vision</span> and will benefit from taking an eyesight test at an optometrist."])}
  },
  "page-distance": {
    "info": {
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instructions for the distance vision test</h3><p>You will need a helper to test your distance vision.<br><span class='underline'>How to test your distance vision.</span></p><div class='first'><div class='text'><p>1. Place your screen 2 metres from your eyes. Measure the distance accurately. Your helper should stand by the screen.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Press \"Test\" when you are both ready.</p>"])},
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instructions for reading vision test</h3><p>You can test your reading vision alone.<br><span class='underline'>How to test your reading vision:</span></p><div class='first'><div class='text'><p>1. Place the screen 40 cm from your eyes. Measure the distance accurately.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Press \"Continue\" when you are ready.</p>"])}
    },
    "select-distance": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm – for reading<br>You can take the test on your own."])},
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 meters – for distance vision<br>You need a helper to take the test."])},
      "button-text-2-m-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 meters"])},
      "button-text-40-cm-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 40 cm"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select 2 meters"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select 40 cm"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyesight test"])},
    "set": {
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Select distance</h2>You can test your eyesight at two distances:"])}
    }
  },
  "page-test": {
    "notice-too-many-errors": {
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>You have now tested your right eye.<br>You can now test your left eye.<br>Cover your <strong>right</strong> eye.<br>Keep the screen ", _interpolate(_named("distance")), " away from your eyes<br>Press \"Next round\".</p>"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two errors"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>You have now tested your left eye and completed the test. <br>Press \"Show results\".</p>"])}
    },
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have now tested your right eye."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>You can now test your left eye.<br>Cover your <strong>right</strong> eye.<br>Keep the screen ", _interpolate(_named("distance")), " away from your eyes<br>Press \"Next round\".</p>"])}
    },
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change eyes"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show results"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Down"])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyesight test"])},
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Testing</strong>"])},
    "change-eye-text-left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cover your left eye and keep the screen ", _interpolate(_named("distance")), " away from your eyes."])},
    "as-you-test-your-right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… as you test your right eye"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It is time to change eyes. Now cover your right eye as you test your left eye.<br />Remember to keep ", _interpolate(_named("distance")), " between your eyes and the screen."])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover your left eye"])},
    "made-two-errors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have made two errors and it is time to change eyes.<br />Now cover your right eye as you test your left eye.<br />Remember to keep ", _interpolate(_named("distance")), " between your eyes and the screen."])}
  },
  "page-scale": {
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Smaller</p>"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smaller -5"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyesight test"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bigger +5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Bigger</p>"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smaller -1"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bigger +1"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your device on a table and a credit card sized card on the screen.</li><li>Place the narrow side for your card between the cropmarks.</li><li>Use the buttons (minus and plus) to adjust the screen.</li><li>The cropmarks on the screen must align with the narrow side of your card.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place a credit card sized card on the screen and place the narrow side of your card between the crop marks.</li><li>Use the buttons (minus and plus) to adjust the screen.</li><li>The cropmarks on the screen must align with the narrow side of your card.</li></ol>"])}
  },
  "page-in-full-brightness": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go inside and turn the brightness on your device to the maximum level."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full brightness"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "page-checklist": {
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have adjusted the screen using a standard card (the size of a credit card)"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have turned up the brightness on my device to its maximum level"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK - I am ready"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am inside"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I have measured the distance between myself and the screen according to the test I am taking (", _interpolate(_named("distance")), ")"])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am wearing my glasses or contact lenses (only if you normally use  glasses or contact lenses)"])}
  },
  "page-measure-40-cm": {
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place the screen 40 cm from your eyes"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An A4 piece of paper is approximately 20 cm wide.<br>Place two A4 pieces of paper next to each other (vertically) and you know approximately how far 40 cm is."])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here if you do not have a ruler or other means of measuring."])},
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place your screen 2 metres from your eyes"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a ruler<br>to measure the distance"])}
  },
  "page-how-it-works": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify the opening in the C-symbols using the arrow keys, the swipe-function or clicking on the buttons."])}
  },
  "global": {
    "investigational-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For investigational use only"])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incompatible browser"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 meters"])},
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to basic mode"])},
    "screen-warning": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you’re using a smartphone and holding it vertically, try tilting the phone horizontally."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your screen is too narrow to carry out the DIY Digital Visual Acuity Test. "])}
    },
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select distance"])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing your right eye (left eye covered)"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust screen"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read instruction"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Eyesight test"])])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't use the advanced version on mobile"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing your left eye (right eye covered)"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your actual browser is not supported. Please use one of the following browsers:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome for iOS or Android"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentials"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify the openings in the C-symbols using the arrow keys, swipe function or other means to give your answers."])},
    "going-to-about-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to leave the test. If you do so, you will have to start over."])},
    "before-you-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "go-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to"])},
    "go-to-about-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to \"About the test\""])},
    "about-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the test"])},
    "about-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the result"])},
    "taking-the-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking the test"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your result"])}
  },
  "page-keep-them": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep them on"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your usual glasses or contact lenses during the test.<br />For example reading glasses for the 40 cm test and normal glasses for the test at 2 m."])}
  },
  "page-keep-screen-horizontal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal screen"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep your screen horizontal throughout the test."])}
  },
  "page-2-metres": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need a helper to take the 2 meter test – for distance vision"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep a distance of 2 meter between yourself and your screen.<br>Your helper will enter your answers."])}
  },
  "page-intro": {
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Visit the eye-sight.org website to learn more</a>"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen<br/>Ny Kongensgade 20<br/>1557 Copenhagen"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This test can assess your visual acuity at two distances: 40 cm (reading vision) and 2 meters (distance vision).<br />You can use the test at home, at work, or wherever you prefer.<br />The test supports detection of reduced visual acuity. It is intended to evaluate whether you will benefit from a full eyesight test at an optometrist.<br /><br />The current version of the test is intended to be used by Danish residents only."])},
    "intro-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This test can assess your visual acuity at two distances: 40 cm (reading vision) and 2 meters (distance vision).<br />You can use the test at home, at work, or wherever you prefer.<br />The test supports detection of reduced visual acuity. It is intended to monitor the evolution of your visual acuity and shall be used only in the context of the clinical investigation conducted by Dr Sandfeld.<br /><br />The current version of the test is intended to be used by Danish residents only."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel. +45 33 69 11 00<br /> kontakt", "@", "ojenforeningen.dk"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the eyesight test"])},
    "top-title-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the eyesight test advanced version"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start"])},
    "investigational-only-advanced-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr Lisbeth Sandfeld, Eye Dept., Zealand University Hospital, 4000 Roskilde, Denmark"])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will need a credit card sized card to adjust the screen settings before starting the test."])}
  },
  "page-proper-dimensions": {
    "proper-device-tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The actual screen size of your device is not supported<br />You should have a window size of min 8 cm x 3.3 cm<br />Please switch to a compliant device"])},
    "proper-device-desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The actual screen size of your device is not supported<br />You should have a window size of min 14 cm x 6.3 cm<br />Please switch to a compliant device"])}
  },
  "about-page": {
    "how-it-works-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the test, you are to identify an opening in a series of C-symbols. The opening in the C-symbol can be either in the top, the bottom, to the right or to the left. Depending on the device, use the arrow keys, swipe-function, or other means to give your answers. The visual acuity is measured in Snellen fractions ranging from 6/6 (perfect vision) to 6/60 (social blindness). The smaller symbols you can correctly identify the better vision you have."])},
    "declaration-of-conformity-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark´s free, online do-it-yourself eyesight test:<br />Declaration of conformity"])},
    "what-it-means-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What it means"])},
    "basic-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight test has divided the Snellen fractions into three categories: Normal vision, acceptable vision and impaired vision.<br /><br />Normal vision (marked with green) includes results between 6/6 and 6/9. <br />Acceptable vision (marked with yellow) includes results between 6/10 and 6/12.<br />Impaired vision (marked with red) includes results between 6/15 and 6/60."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the test and your test results"])},
    "how-it-works-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "about-test-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight test can assess your visual acuity at two distances: 40 cm (reading vision) and 2 meters (distance vision). It is intended to evaluate whether you will benefit from a full eyesight test at an optometrist. The current version of the test is intended to be used by Danish residents only.<br /><br />The eyesight test is based upon the ETDRS chart that you can meet at your optometrist or ophthalmologist. Using the C-symbols in various sizes, the test is designed to measure your visual acuity – that is your ability to see clearly. The size and design are based on system of the ETDRS charts."])},
    "about-test-text-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight test can assess your visual acuity at two distances: 40 cm (reading vision) and 2 meters (distance vision). It is intended to monitor the evolution of your visual acuity and shall be used only in the context of the clinical investigation conducted by Dr Sandfeld. <br /><br />The current version of the test is intended to be used by Danish resident only.<br /><br />The eyesight test is based upon the ETDRS chart that you can meet at your optometrist or ophthalmologist. Using the C-symbols in various sizes, the test is designed to measure your visual acuity – that is your ability to see clearly. The size and design are based on system of the ETDRS charts."])},
    "what-it-means-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test is given as either normal, acceptable or impaired vison.<br /><br />If the result is normal vision, you should do nothing more.<br /><br />If the result is acceptable or impaired, we recommend that you consult an optometrist. You will benefit from taking a professional eyesight test at an optometrist. If an optometrist cannot help you to see clearly, it is important that you find out why. Always contact an eye care specialist (ophthalmologist) to find out what is causing your inability to see clearly."])},
    "what-it-means-text-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test is given as either normal, acceptable or impaired vison. It also provides you with the numeric value of the test expressed in Snellen fraction so you can compare how your visual acuity evolves over time and share this information with your medical professional. For the clinical investigation, it is the value you have to report."])},
    "always-respect-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always keep the indicated distance between the screen on which you are taking the test and your eyes. A different distance – shorter or longer – will impact your result thus making it imprecise. This test can be taken at 40  cm (reading vision) and 2 meters (distance vision).<br /><br />You test one eye at a time. It is important that you cover the other eye as instructed during the test."])},
    "advanced-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight has divided the Snellen fractions into three categories: Normal vision, acceptable vision and impaired vision. <br /><br />Normal vision (marked with green) includes the following results: 6/6, 6/7, 6/8 and 6/9<br/>Acceptable vision (marked with yellow) includes the following results: 6/10 and 6/12<br />Impaired vision (marked with red) includes the following results: 6/15, 6/20, 6/30 and 6/60"])},
    "declaration-of-conformity-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We declare that Fight for Sight, Denmark’s free, online do-it-yourself eyesight test bearing CE marking complies with the general safety and performance requirements of the Medical Device Regulation MDR 2017/745 Annex I. Applicable harmonized standards have been used to demonstrate the safety and effectiveness of the device when used in accordance with its intended use and the accompanying instructions.<br/><br/>The following standards were used:<br/><br/><span class=\"body-bold\">Manufacturing</span>: EN ISO 13485:2016<br/><span class=\"body-bold\">Risk management</span>: EN ISO 14971:2019<br/><span class=\"body-bold\">Software lifecycle</span>: EN 62304:2006/A1:2015<br/><br/>Each version of the software is released in accordance with records certifying compliance with the specifications."])},
    "investigational-only-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device use is restricted to the clinical investigation, it may not be used for any other purpose."])}
  },
  "page-ce": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen<br/>Ny Kongensgade 20<br/>1557 København"])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Visit the eye-sight.org website to learn more</a>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyesight test"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00d8jenforeningens<br />free, online do-it-yourself vision test"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontakt", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Classification:</span><br/>Class I according to the Medical Device Regulation MDR 2017/745, Annex VIII, Rule 11.<br/><br/>We declare that the DIY Digital Visual Acuity Test System and bearing CE marking complies with the general safety and performance requirements of the Medical Device Regulation MDR 2017/745 Annex I. Applicable harmonized standards have been used to demonstrate the safety and effectiveness of the device when used in accordance with its intended use and the accompanying instructions.<br/><br/>The following standards were used:<br/><br/><span class=\"body-bold\">Manufacturing</span>: EN ISO 13485:2016<br/><span class=\"body-bold\">Risk management</span>: EN ISO 14971:2019<br/><span class=\"body-bold\">Software lifecycle</span>: EN 62304:2006/A1:2015<br/><br/>Each version of the software is released in accordance with records certifying compliance with the specifications"])}
  }
}